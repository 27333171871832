import React from 'react';
import './Banner.css';
import  BannerPinacoteca  from '../assets/images/banner_pinacoteca85.jpg'; 
import  BannerPaulista  from '../assets/images/banner_paulista19.jpg'; 
import  Ed  from '../assets/images/banner_youtube.jpg'; 
import  banner_seja  from '../assets/images/banner_sempre_tem_um_futuro_comecando_em_cada_um_de_nos.jpg'; 
import Carousel from 'react-bootstrap/Carousel';

export default function Banner () {
    return (
        <div className='carousel'>
          <Carousel fade>
          <Carousel.Item>
              <img
                className="d-block w-100"
                src={BannerPinacoteca}
                alt="Bem-vindos"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={BannerPaulista}
                alt="Bem-vindos"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={banner_seja}
                alt="Bem-vindos"
              />
            </Carousel.Item>
          </Carousel>
        </div>
      )
    }
    