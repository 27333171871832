import * as React from 'react';
import './PortfolioMain.css';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import VueJSECommerce0 from "../../assets/images/vuejs_ecommerce0.jpg";
import AppSkore0 from "../../assets/images/appskore0.jpg";
import NetflixClone0 from "../../assets/images/netflix_clone0.jpg";
import ReactSpa0 from "../../assets/images/react_spa0.jpg";
import ReactGoogleChart0 from "../../assets/images/react_google_chart0.jpg";
import Sitedoed0 from "../../assets/images/sitedoed0.jpg";
import GrupoTribunaCadastro0 from "../../assets/images/grupotribuna_cadastro_login.jpg";
import CiadoFisico0 from "../../assets/images/cia_do_fisico0.jpg";
import ProjetoRecupera0 from "../../assets/images/projeto_recupera0.jpg";
import GrupoTribunaCadastroGeradordeAssinatura from "../../assets/images/grupotribuna_gerador_de_assinatura.jpg";
import MundoOng1 from "../../assets/images/mundo_ong0.jpg";
import Acamby0 from "../../assets/images/acamby0.jpg";
import { Link } from 'react-router-dom';
import MainNav from '../MainNav';
import SocialNetworks from '../SocialNetworks';
import Footer from '../Footer';
import { portFolioData } from './PortfolioData';
import Breadcrumb from '../Breadcrumb';

export default function NetflixClone () {

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      }));
    
    return (
      <>
        <MainNav />
        {portFolioData.map((data) => {
          return (
            <Breadcrumb title={data.title} desc={data.desc}/>
          );
        })}
        <div className='PortfolioMain'>
            <p>Demonstração de alguns de nossos projetos, utilizando diferentes tecnologias,
              para ilustrar as diversas possibilidades do Desenvolvimento WEB.
              Algumas descrições estão em produção e serão atualizadas, em breve!
            </p>
            <Box sx={{ flexGrow: 1, mt:10 }}>
              <Grid container spacing={2}>
              <Grid item xs={11} md={3}>
                  <Item>
                    <div className='imageHolder'>
                      <Link to="/portfolio/vuejs_ecommerce">
                        <img src={VueJSECommerce0} width="100%" alt="Loja Virtual Desenvolvida em VueJS"/>                      
                        <div className='caption'>  
                          <ul>
                            <li>
                              <span className='title'>
                                VueJS E-Commerce
                              </span>
                            </li>
                            <li>
                              <span className='subtitle'>
                                Loja Virtual Desenvolvida em VueJS com NUXT
                              </span>
                            </li>
                          </ul>  
                        </div>
                      </Link>
                    </div>
                  </Item>
                </Grid>
                <Grid item xs={11} md={3}>
                  <Item>
                    <div className='imageHolder'>
                      <Link to="/portfolio/react_spa">
                        <img src={ReactSpa0} width="100%" alt="React Single Page Application"/>                      
                        <div className='caption'>  
                          <ul>
                            <li>
                              <span className='title'>
                                React Single Page Application
                              </span>
                            </li>
                            <li>
                              <span className='subtitle'>
                                Tutorial de React, em inglês
                              </span>
                            </li>
                          </ul>  
                        </div>
                      </Link>
                    </div>
                  </Item>
                </Grid>
                <Grid item xs={11} md={3}>
                  <Item>
                    <div className='imageHolder'>
                      <Link to="/portfolio/netflix_clone">
                        <img src={NetflixClone0} width="100%" alt="Netflix Clone"/>
                        <div className='caption'>
                          <ul>
                            <li>
                              <span className='title'>
                              NetFlix Clone
                              </span>
                            </li>
                            <li>
                              <span className='subtitle'>
                                API React com dados dinâmicos do TMDB
                              </span>
                            </li>
                          </ul>
                        </div>
                      </Link>
                    </div>
                  </Item>
                </Grid>
                <Grid item xs={11} md={3}>
                  <Item>
                    <div className='imageHolder'>
                      <Link to="/portfolio/appskore">
                        <img src={AppSkore0} width="100%" alt="AppSkore"/>                      
                        <div className='caption'>  
                          <ul>
                            <li>
                              <span className='title'>
                                AppSkore
                              </span>
                            </li>
                            <li>
                              <span className='subtitle'>
                                Um projeto reativo, desenvolvido em VueJS com NUXT e GraphQL
                              </span>
                            </li>
                          </ul>  
                        </div>
                      </Link>
                    </div>
                  </Item>
                </Grid>
                <Grid item xs={11} md={3}>
                  <Item>
                    <div className='imageHolder'>
                      <Link to="/portfolio/react_google_chart">
                        <img src={ReactGoogleChart0} width="100%" alt="React Google Chart"/>
                        <div className='caption'>
                          <ul>
                            <li>
                              <span className='title'>
                                React Google Chart
                              </span>
                            </li>
                            <li>
                              <span className='subtitle'>
                              API React + Google Charts. Atualização dinâmica.
                              </span>
                            </li>
                          </ul>
                        </div>
                      </Link>
                    </div>
                  </Item>
                </Grid>
                <Grid item xs={11} md={3}>
                  <Item>
                    <div className='imageHolder'>
                      <Link to="/portfolio/sitedoed">
                        <img src={Sitedoed0} width="100%" alt="Site do Ed"/>                      
                        <div className='caption'>
                          <ul>
                            <li>
                              <span className='title'>
                                Site do Ed
                              </span>
                            </li>
                            <li>
                              <span className='subtitle'>
                                Site Pessoal do Ed. MVC, Laravel (PHP, MySQL, Html)
                              </span>
                            </li>
                          </ul>
                        </div>
                      </Link>
                    </div>
                  </Item>
                </Grid>
                <Grid item xs={11} md={3}>
                  <Item>
                    <div className='imageHolder'>
                      <Link to="/portfolio/grupotribunacadastro">
                        <img src={GrupoTribunaCadastro0} width="100%" alt="Site do Ed"/>                      
                        <div className='caption'>
                          <ul>
                            <li>
                              <span className='title'>
                                Cadastro Geral - GRUPO TRIBUNA
                              </span>
                            </li>
                            <li>
                              <span className='subtitle'>
                                Cadastro de usuários, eventos, ramais, entre outros do GRUPO TRIBUNA 
                                LAMP Stack (PHP, MySQL, Html), MVC, Laravel. 
                              </span>
                            </li>
                          </ul>
                        </div>
                      </Link>
                    </div>
                  </Item>
                </Grid>
                <Grid item xs={11} md={3}>
                  <Item>
                    <div className='imageHolder'>
                      <Link to="/portfolio/ciadofisico">
                        <img src={CiadoFisico0} width="100%" alt="Cia do Físico"/>
                        <div className='caption'>
                          <ul>
                            <li>
                              <span className='title'>
                                Cia do Físico
                              </span>
                            </li>
                            <li>
                              <span className='subtitle'>
                              HTML5, Javascript, Ajax, CSS, Jquery e Bootstrap
                              </span>
                            </li>
                          </ul>
                        </div>
                      </Link>
                    </div>
                  </Item>
                </Grid>
                <Grid item xs={11} md={3}>
                  <Item>
                    <div className='imageHolder'>
                      <Link to="/portfolio/projeto_recupera">
                        <img src={ProjetoRecupera0} width="100%" alt="Projeto Recupera"/>                      
                        <div className='caption'>
                          <ul>
                              <li>
                                <span className='title'>
                                  Projeto Recupera
                                </span>
                              </li>
                              <li>
                                <span className='subtitle'>
                                Descrição em produção
                                </span>
                              </li>
                            </ul>
                        </div>
                      </Link>
                    </div>
                  </Item>
                </Grid>
                <Grid item xs={11} md={3}>
                  <Item>
                    <div className='imageHolder'>
                      <Link to="/portfolio/grupotribunageradordeassinatura">
                        <img src={GrupoTribunaCadastroGeradordeAssinatura} width="100%" alt="GRUPO TRIBUNA Gerador de Assinatura"/>                      
                        <div className='caption'>
                          <ul>
                              <li>
                                <span className='title'>
                                  GRUPO TRIBUNA - Gerador de Assinatura
                                </span>
                              </li>
                              <li>
                                <span className='subtitle'>
                               Aplicação WEB desenvolvida em HTML5, CSS3, Javascript
                                </span>
                              </li>
                            </ul>
                        </div>
                      </Link>
                    </div>
                  </Item>
                </Grid>
                <Grid item xs={11} md={3}>
                  <Item>
                    <div className='imageHolder'>
                      <Link to="/portfolio/mundoong">
                        <img src={MundoOng1} width="100%" alt="Mundo Ong"/>
                        <div className='caption'>
                          <ul>
                              <li>
                                <span className='title'>
                                Mundo Ong
                                </span>
                              </li>
                              <li>
                                <span className='subtitle'>
                                Descrição em produção
                                </span>
                              </li>
                            </ul>
                          </div>
                        </Link>
                    </div>
                  </Item>
                </Grid>
                <Grid item xs={11} md={3}>
                  <div className='imageHolder'>
                    <Link to="/portfolio/acamby">
                      <img src={Acamby0} width="100%" alt="Acamby Trading"/>   
                      <div className='caption'>
                        <ul>
                          <li>
                            <span className='title'>
                            Acamby Trading
                            </span>
                          </li>
                          <li>
                            <span className='subtitle'>
                            Descrição em produção
                            </span>
                          </li>
                        </ul>
                      </div>
                    </Link>
                  </div>
                </Grid>
              </Grid>
            </Box>  
        </div>
        <SocialNetworks />
        <Footer />  
      </>      

    );

}