export const BreadCrumbData = [
    {
        title: "Vídeos/Crônicas",
        desc: "Outros Pontos de Vista!",
    }
];

export const CronicasData = [
    {  
        id: "1",
        title: "Invisível pra quem não quer ver",
        iframe: "https://www.youtube.com/embed/oM2C-6zSdVI",
    },
    {
        id: "2",
        title: "Aconteça o que acontecer, seja o melhor que puder!",
        iframe: "https://www.youtube.com/embed/gFHKQhRcY08",
    },
    {
        id: "3",
        title: "O que andam dizendo por aí...",
        iframe: "https://www.youtube.com/embed/PHxh_Pl39QM",
    },
    {
        id: "4",
        title: "A Grande Matemática Mágica da Vida",
        iframe: "https://www.youtube.com/embed/vrMpW_RKCkE",
    },
    {
        id: "5",
        title: "A oração do maior vendedor do mundo",
        iframe: "https://www.youtube.com/embed/u5DQ0jB-ySQ",
    },
    {
        id: "6",
        title: "O último discurso",
        iframe: "https://www.youtube.com/embed/Yj1q58bkCbE",
    },
    {
        id: "7",
        title: "Quebre o ciclo",
        iframe: "https://www.youtube.com/embed/h6bspdim0DY",
    },
    {
        id: "8",
        title: "Um dia daqueles",
        iframe: "https://www.youtube.com/embed/z9zVZSzYEsc",
    },
    {
        id: "9",
        title: "Pato ou Águia",
        iframe: "https://www.youtube.com/embed/-r8gaVn-imE",
    },
    {
        id: "10",
        title: "Novos Tempos",
        iframe: "https://www.youtube.com/embed/grMN2VNGm2M",
    },
    {
        id: "11",
        title: "Quem conhece a cor das maças?",
        iframe: "https://www.youtube.com/embed/5xcZpDNiw14",
    },
    {
        id: "12",
        title: "Mais uma guerra",
        iframe: "https://www.youtube.com/embed/31YV49oEWBI",
    },
    {
        id: "13",
        title: "Estratégias de Sucesso do Maior Vendedor do Mundo",
        iframe: "https://www.youtube.com/embed/vBFcAozseno",
    },
    {
        id: "14",
        title: "O nosso e os outros olhares",
        iframe: "https://www.youtube.com/embed/FWduHHoG160",
    },
    {
        id: "15",
        title: "Você precisa saber isso!",
        iframe: "https://www.youtube.com/embed/vf-5zXhZNgQ",
    },
];


