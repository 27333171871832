export const BreadCrumbData = [
    {
        title: "Vídeos/Tecnologia",
        desc: "Mr. Ed, Plural de um Jeito Único!",
    }
];

export const TecnologiaData = [
    {  
        id: "1",
        title: "O PIX é seguro mesmo? Ou tem algum cuidado que devemos tomar?",
        iframe: "https://www.youtube.com/embed/gJgOHYKV2VE",
    },
];