import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp } from "@fortawesome/free-solid-svg-icons"
import './Footer.css'

export default function Footer() {
    
    const [showTopBtn, setShowTopBtn] = useState(false);
    
    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 400) {
                setShowTopBtn(true);
            } else {
                setShowTopBtn(false);
            }
        });
    }, []);

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

  return (
    <div className='footer'>
        <div className="row m-0 justify-content-md-center">
            <div className="p-2 m-2 float-left col-sm-10 col-md-4 col-xl-3 ">
                <ul>Pexels Videos
                    <li>
                        <a href="https://www.pexels.com/pt-br/video/roupao-de-banho-roupao-banheiro-toalete-5657581/" target='_blank' rel="noreferrer">Diva Plavaguma</a>
                    </li>
                    <li>
                        <a href="https://www.pexels.com/pt-br/video/afeicao-carinho-simpatia-borrao-4873447/" target='_blank' rel="noreferrer">Vlada Karpovich</a>
                    </li>
                    <li>
                        <a href="https://www.pexels.com/pt-br/video/uma-visao-de-baloes-de-ar-quente-no-ar-e-uma-mulher-dirigindo-seu-veiculo-3015510/" target='_blank' rel="noreferrer">Taryn Elliot</a>
                    </li>
                </ul>
            </div>
            <div className="p-2 m-2 float-left col-sm-12 col-md-4 col-xl-3 ">
                <ul>Pexels Videos
                    <li>
                        <a href="https://www.pexels.com/pt-br/video/realizacao-conquista-facanha-adulto-7735852/" target='_blank' rel="noreferrer">Mikhail Nilov</a>
                    </li>
                    <li>
                        <a href="https://www.pexels.com/pt-br/video/alegre-animado-bem-disposto-refrigeracao-4881632/" target='_blank' rel="noreferrer">cottonbro</a>
                    </li>
                </ul>
            </div>
            <div className="p-2 m-2 float-left col-sm-12 col-md-4 col-xl-3 ">
                <ul>Pexels Videos
                    <li>
                        <a href="https://www.pexels.com/pt-br/video/pessoas-se-agrupando-para-uma-selfie-em-grupo-3189293/" target='_blank' rel="noreferrer">cottonbro</a>
                    </li>
                </ul>
            </div>
        </div>
         <div className="row justify-content-md-center">
            <p class="text-center">sitedoed 2022 &reg;</p>
         <div className='top-to-btn'>
            {" "}
                {showTopBtn && (
                <FontAwesomeIcon icon={faArrowUp} className="arrow_up" onClick={goToTop} />
            )}{" "}
        </div>
        </div>
    </div>
  )
}
