export const BreadCrumbData = [
    {
        title: "Videos",
        desc: "Mr. Ed, Plural de um Jeito Único!",
    }
];

export const videoData = [
    {  
        title: "Um pouco de Rock Nacional",
        iframe: "https://www.youtube.com/embed/vbGa8nTIimg",
    },
    {
        title: "Era uma vez - Cover",
        iframe: "https://www.youtube.com/embed/SNcpxcU2zmA",
    },
    {
        title: "Trevo (Tu) - Cover de AnaVitória",
        iframe: "https://www.youtube.com/embed/KBL5G-t9rjk",
    },
    {
        title: "Mantra - Om Mani Padme Hum",
        iframe: "https://www.youtube.com/embed/ad87h2pAurw",
    },
    {
        title: "Sereia (música de: Juh Brito)",
        iframe: "https://www.youtube.com/embed/RpNFsF4ry74",
    },
];