import React from 'react';


export default function HomeShorts() {
  return (
    <div>
        <div className="row m-0 mt-5 d-flex justify-content-between">
            <div className="p-0 m-2 pt-2 bg-body rounded float-left col-sm-12 col-md-9 col-xl-9 ">
                <h2>E uns vídeos shorts</h2>
            </div>
            <div className="p-0 m-5 pt-2 float-left col-sm-12 col-md-4 col-xl-3 ">
            <iframe width="100%" height="500" src="https://www.youtube.com/embed/kVOsIFFjqgI" title="Foco, Força e Fé   Os três Efes da Felicidade! #vida #felicidade #shorts" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div className='p-3'>
                </div>
            </div>
            <div className="p-0 m-5 pt-2 float-left col-sm-12 col-md-4 col-xl-3 ">
                <iframe width="100%" height="500" src="https://www.youtube.com/embed/nk3CcSYOdpU" title="O ódio ou a esperança, o que vai vencer? #vida #politica #shorts" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div className='p-3'>
                </div>
            </div>
            <div className="p-0 m-5 pt-2 float-left col-sm-12 col-md-4 col-xl-3 ">
                <iframe width="100%" height="500" src="https://www.youtube.com/embed/ieGAMPKMqH8" title="Só existe vitória depois da batalha!" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div className='p-3'>
                </div>
            </div>
            <div className="p-0 m-5 pt-2 float-left col-sm-12 col-md-4 col-xl-3 ">
                <iframe width="449" height="500" src="https://www.youtube.com/embed/Pxs36wF7BNA" title="Espere o melhor, prepare-se para o pior e siga em frente de uma forma ou de outra!" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div className='p-3'>
                </div>
            </div>
            <div className="p-0 m-5 pt-2 float-left col-sm-12 col-md-4 col-xl-3 ">
                <iframe width="100%" height="500" src="https://www.youtube.com/embed/GWuXCZJ-eWs" title="Faça as pazes contigo  Viva mais e melhor #vida #autoconhecimento #shorts" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div className='p-3'>
                </div>
            </div>
            <div className="p-0 m-5 pt-2 float-left col-sm-12 col-md-4 col-xl-3 ">
                <iframe width="100%" height="500" src="https://www.youtube.com/embed/YwzaLJ9S8ak" title="A gente consegue! #vida #autoconhecimento #shorts" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div className='p-3'>
                </div>
            </div>
            <div className="p-0 m-5 pt-2 float-left col-sm-12 col-md-4 col-xl-3 ">
                <iframe width="100%" height="500" src="https://www.youtube.com/embed/oe11YMfGm9E" title="Não precisa ser fácil, só precisa ser possível! #vida  #fé  #autoconhecimento" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div className='p-3'>
                </div>
            </div>
            <div className="p-0 m-5 pt-2 float-left col-sm-12 col-md-4 col-xl-3 ">
                <iframe width="100%" height="500" src="https://www.youtube.com/embed/jnLl1b2Eirg" title="As pessoas certas estão no teu caminho #vida  #fé #shorts" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div className='p-3'>
                </div>
            </div>
            <div className="p-0 m-5 pt-2 float-left col-sm-12 col-md-4 col-xl-3 ">
                <iframe width="100%" height="500" src="https://www.youtube.com/embed/d3vjrHQQfa8" title="Perdão não é esquecimento #vida #autoconhecimento #shorts" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div className='p-3'>
                </div>
            </div>
            <div className="p-0 m-5 pt-2 float-left col-sm-12 col-md-4 col-xl-3 ">
                <iframe width="100%" height="500" src="https://www.youtube.com/embed/gqS7UYsBTOA" title="A única coisa que vale a pena! #vida #fé #shorts" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div className='p-3'>
                </div>
            </div>
            <div className="p-0 m-5 pt-2 float-left col-sm-12 col-md-4 col-xl-3 ">
                <iframe width="100%" height="500" src="https://www.youtube.com/embed/vo9w6ORUgks" title="Fé é uma coisa, religião é outra #vida #fé #shorts" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div className='p-3'>
                </div>
            </div>
            <div className="p-0 m-5 pt-2 float-left col-sm-12 col-md-4 col-xl-3 ">
                <iframe width="100%" height="500" src="https://www.youtube.com/embed/1NksvxjqDO8" title="Tudo depende do que cada um alimenta dentro de si #vida #atitude #shorts" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div className='p-3'>
                </div>
            </div>
            <div className="p-0 m-5 pt-2 float-left col-sm-12 col-md-4 col-xl-3 ">
                <iframe width="100%" height="500" src="https://www.youtube.com/embed/4OsiMFuH7KU" title="Você tem um minuto pra ti? #vida  #tempo  #shorts" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div className='p-3'>
                </div>
            </div>
            <div className="p-0 m-5 pt-2 float-left col-sm-12 col-md-4 col-xl-3 ">
                <iframe width="100%" height="500"  src="https://www.youtube.com/embed/DZJYad0h-qU" title="É preciso ser feliz como a gente é #vida #autoconhecimento #shorts" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div className='p-3'>
                </div>
            </div>
            <div className="p-0 m-5 pt-2 float-left col-sm-12 col-md-4 col-xl-3 ">
                <iframe width="100%" height="500"  src="https://www.youtube.com/embed/1TkyKLcgres" title="Setembro amarelo - Campanha de prevenção ao suicídio #vida #setembroamarelo #shorts" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div className='p-3'>
                </div>
            </div>
            <div className="p-0 m-5 pt-2 float-left col-sm-12 col-md-4 col-xl-3 ">
                <iframe width="100%" height="500"   src="https://www.youtube.com/embed/suvbFvjB6GM" title="Existe um sol brilhando todos os dias por todos nós #vida #politica #shorts" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div className='p-3'>
                </div>
            </div>
            <div className="p-0 m-5 pt-2 float-left col-sm-12 col-md-4 col-xl-3 ">
                <iframe width="100%" height="500"  src="https://www.youtube.com/embed/zbhXwwWIZ7A" title="Pessoas como nós #vida #pessoas #shorts" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div className='p-3'>
                </div>
            </div>
            <div className="p-0 m-5 pt-2 float-left col-sm-12 col-md-4 col-xl-3 ">
                <iframe width="100%" height="500"   src="https://www.youtube.com/embed/2ufkXdmZ-1g" title="Sempre tem um futuro começando pra cada um de nós #vida #oportunidade #shorts" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div className='p-3'>
                </div>
            </div>
            <div className="p-0 m-5 pt-2 float-left col-sm-12 col-md-4 col-xl-3 ">
            <iframe width="100%" height="500"   src="https://www.youtube.com/embed/2TbnZy4gPo0" title="Renove-se por dentro e por fora #vida #shorts #felicidade" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div className='p-3'>
                </div>
            </div>
            <div className="p-0 m-5 pt-2 float-left col-sm-12 col-md-4 col-xl-3 ">
                <iframe width="100%" height="500"   src="https://www.youtube.com/embed/kfGbhTmF39U" title="Quem procura acha e quem não quer perde a chance #vida #escolhas #shorts" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div className='p-3'>
                </div>
            </div>
            <div className="p-0 m-5 pt-2 float-left col-sm-12 col-md-4 col-xl-3 ">
                <iframe width="100%" height="500"   src="https://www.youtube.com/embed/j6Fm51PXnQ4" title="Feliz dia de Hoje #vida #felicidade #shorts" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div className='p-3'>
                </div>
            </div>    
            <div className="p-0 m-5 pt-2 float-left col-sm-12 col-md-4 col-xl-3 ">
                <iframe width="100%" height="500"   src="https://www.youtube.com/embed/WrUnxacAmYA" title="É você que escolhe o seu destino #escolhas #vida #shorts" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div className='p-3'>
                </div>
            </div>
            <div className="p-0 m-5 pt-2 float-left col-sm-12 col-md-4 col-xl-3 ">
                <iframe width="100%" height="500"   src="https://www.youtube.com/embed/Ms_KTlkfo00" title="O que faz a diferença #escolhas #shorts #forcadevontade" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div className='p-3'>
                </div>
            </div>
            <div className="p-0 m-5 pt-2 float-left col-sm-12 col-md-4 col-xl-3 ">
            <iframe width="100%" height="500"   src="https://www.youtube.com/embed/TO6VghtAxI0" title="A única constante na vida é a mudança! #vida #atitude #shorts" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div className='p-3'>
                </div>
            </div>
        </div>
    </div>
  )
}
