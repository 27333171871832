import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagramSquare, faFacebookSquare, faTwitterSquare, faYoutubeSquare, faTiktok } from '@fortawesome/free-brands-svg-icons';
import './SocialNetworks.css';

export default function SocialNetworks() {
  return (
    <div>
        <div className="social_media_icons">
            <div className=" linha">
                <a href="https://www.instagram.com/sitedoed/" target="_blank" rel='noreferrer'>
                    <span  className="instagram" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Instagram: @sitedoed">
                      <FontAwesomeIcon icon={ faInstagramSquare } />
                    </span>
                </a>
            </div>
            <div className=" linha">
                <a href="https://www.facebook.com/sitedoed" target="_blank" rel='noreferrer'>
                    <span className="facebook" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Facebook: @sitedoed">
                      <FontAwesomeIcon icon={ faFacebookSquare } />
                    </span>
                </a>
            </div>
            <div className=" linha">
                <a href="https://twitter.com/sitedoed" target="_blank" rel='noreferrer'>
                    <span className="twitter" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Twitter: @sitedoed">
                      <FontAwesomeIcon icon={ faTwitterSquare } />
                    </span>
                </a>
            </div>
            <div className=" linha">
                <a href="https://www.youtube.com/sitedoed" target="_blank" rel='noreferrer'>
                    <span className="youtube" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Youtube: @sitedoed">
                      <FontAwesomeIcon icon={ faYoutubeSquare } />
                    </span>
                </a>
            </div>
            <div className=" linha">
                <a href="https://www.tiktok.com/@sitedoed" target="_blank" rel='noreferrer'>
                    <span  className="instagram" data-bs-toggle="tooltip" data-bs-placement="bottom" title="TikTok: @sitedoed">
                      <FontAwesomeIcon icon={ faTiktok } />
                    </span>
                </a>
            </div>
        </div>  
    </div>
  )
}
