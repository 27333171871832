import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Bio from './components/Bio';
import Videos from './components/Videos';
import Portfolio from './components/portfolio/PortfolioMain';
import VueJSECommerce from './components/portfolio/VueJSECommerce';
import NetflixClone from './components/portfolio/NetflixClone';
import AppSkore from './components/portfolio/AppSkore';
import ReactSPA from './components/portfolio/ReactSPA';
import ReactGoogleChart from './components/portfolio/ReactGoogleChart';
import SitedoEd from './components/portfolio/SitedoEd';
import GrupoTribunaCadastro from './components/portfolio/GrupoTribunaCadastro';
import CiadoFisico from './components/portfolio/CiadoFisico';
import ProjetoRecupera from './components/portfolio/ProjetoRecupera';
import GrupoTribunaGeradordeAssinatura from './components/portfolio/GrupoTribunaGeradordeAssinatura';
import MundoOng from './components/portfolio/MundoOng';
import Acamby from './components/portfolio/Acamby';
import Cards from './components/Cards';
import Cronicas from './components/Cronicas';
import Tecnologia from './components/Tecnologia';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path='/' element={<App />} />
      <Route path='/home' element={<Home />} />
      <Route path='/bio' element={<Bio />} />
      <Route path='/cards' element={<Cards />} />
      <Route path='/videos' element={<Videos />} />
      <Route path='/videos/musica' element={<Videos />} />
      <Route path='/videos/cronicas' element={<Cronicas />} />
      <Route path='/videos/tecnologia' element={<Tecnologia />} />
      <Route path='/portfolio' element={<Portfolio />} />
      <Route path='/portfolio/vuejs_ecommerce' element={<VueJSECommerce />} />
      <Route path='/portfolio/netflix_clone' element={<NetflixClone />} />
      <Route path='/portfolio/appskore' element={<AppSkore />} />
      <Route path='/portfolio/react_spa' element={<ReactSPA />} />
      <Route path='/portfolio/react_google_chart' element={<ReactGoogleChart />} />
      <Route path='/portfolio/sitedoed' element={<SitedoEd />} />
      <Route path='/portfolio/grupotribunacadastro' element={<GrupoTribunaCadastro />} />
      <Route path='/portfolio/ciadofisico' element={<CiadoFisico />} />
      <Route path='/portfolio/projeto_recupera' element={<ProjetoRecupera />} />
      <Route path='/portfolio/grupotribunageradordeassinatura' element={<GrupoTribunaGeradordeAssinatura />} />
      <Route path='/portfolio/mundoong' element={<MundoOng />} />
      <Route path='/portfolio/acamby' element={<Acamby />} />
      <Route
      path="*"
      element={
        <main style={{ padding: "1rem" }}>
          <p>Página não encontrada!</p>
        </main>
      }
    />
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
