import React from 'react';
import MainNav from './MainNav';
import SocialNetworks from './SocialNetworks';
import Footer from './Footer';
import './Bio.css';
import { BreadCrumbData } from './BioData';
import edbio from '../assets/images/ed_profile.jpg';
import Breadcrumb from './Breadcrumb';


export default function Bio() {
    return (
    <div>
        <MainNav />
        {BreadCrumbData.map((data) => {
            return (
                <Breadcrumb title={data.title} desc={data.desc} />
            );
        })}
        <div className='bio'>
            <div className="row justify-content-md-center mt-5">
                <div className="col-md-8 bg-light p-5">
                    <h2>
                        Edson O. Vieira, Ed Oliveira, o Mr. Ed - Plural de um jeito único
                    </h2>
                    <div className='row mt-5'>
                        <div className='col-md-2'>
                            <img width="100%" src={edbio} alt="Ed Bio" />
                        </div>
                        <div className='col-md-10'>
                            <div className='row'>
                            
                                <h3>Resumindo:</h3> 
                                <p>
                                    Engenheiro de Computação, especialista em Comunicação & Mídia. Músico, escritor e produtor de conteúdo nas horas vagas.
                                    Gente boa, family-friendly, pet-friendly e por aí vai! Nerd, Geek, High Tech e um monte de outras coisas interessantes, estranhas e inusitadas!
                                </p>
                            </div>
                            <div>
                                <h4>
                                    Mr. Ed, por ele mesmo:
                                </h4>
                                <h6>
                                Alguém, sempre tentando e muitas vezes conseguindo ser, feliz!
                                </h6>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-4'>
                      
                        <h4><span className=''>Formação Acadêmica</span></h4>
                        <ul>
                            <li>MBA em Big Data e Inteligência Competitiva</li>
                            <li>Especialização em Engenharia de Segurança no Trabalho</li>
                            <li>MBA Comunicação e Mídia</li>                            
                            <li>Bacharelado em Engenharia de Computação</li>
                            <li>Tecnologia em Marketing</li>
                            <li>Tecnologia em Análise e Desenvolvimento de Sistemas</li>
                        </ul>    
                
                    </div>
                    <div className='row mt-4'>
                        <h4>Profissional de Tecnologia da Informação e da Comunicação há mais de dez anos</h4>
                    </div>
                    <div className='row mt-4'>
                        <h4>Desenvolvendo aplicações e sistemas para internet desde:</h4>
                        <ul>
                            <li> A criação de sites (Front-end/Back-end), identidade visual, banco de dados;</li>
                            <li>Plataformas de gerenciamento de conteúdo (CMS) e comércio eletrônico;</li>
                            <li>Otimização de sites para publicidade online e mecanismos de busca;</li>
                            <li>Comunicação estratégica para negócios online;</li>
                            <li>Até a criação de conteúdo para internet, entre outros.</li>
                        </ul>
                    </div>
                    <div className='row mt-4'>
                        <h4>Linguagens de programação e ferramentas mais utilizadas</h4>
                        <ul>
                            <li>MVC e Programação Orientada a Objetos, PHP, JAVA, Javascript, Node, HTML, CSS;</li>
                            <li>Spring Boot, Laravel, React, Jquery, Bootstrap; </li>
                            <li>GIT, MYsql, NOsql;</li>
                            <li>Cloud AWS (Amazon), Ubuntu Server;</li>
                            <li>Photoshop, Illustrator, Google Adworks, Facebook Ads, Polopoly, Wordpress</li>
                        </ul>                        
                    </div>
                    <div className='row mt-4'>
                        <h4>Outras Especializações</h4>
                        <ul>
                            <li>
                                Especialização em Marketing Digital - Universidade de Illinois at Urbana-Champaign/COURSERA ;
                            </li>
                            <li>
                                Especialização em Gestão de Marca e Carreira - Career Brand Management Specialization - SUNY - State University of New York/COURSERA;
                            </li>
                        </ul>                        
                    </div>
                    <div className='row mt-4'>
                    <h4>Formação Complementar</h4>
                        <ul>
                            <li>Scrum Fundamentals Certified Credential – SFC – Scrum Study; </li>
                            <li>Java - Programação Aplicada – BrasilMaisTi.</li>
                            <li>Programa de Tecnologia da Informação (10 cursos) – Intel;</li>
                            <li>Programa de Segurança da Informação (7 cursos) – Intel;</li>
                            <li>Curso Técnico/Profissionalizante de Áudio/Visual – ONG Arte no Dique;</li>
                            <li>Programa  de Voluntariado para a formação de profissionais de áudio/visual - NETCidade.</li>
                        </ul>                        
                    </div>
                </div>    
            </div>
        </div>
        <SocialNetworks />
        <Footer />
    </div>
  )
}
