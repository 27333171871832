import React from 'react';
import MainNav from './MainNav';
import SocialNetworks from './SocialNetworks';
import Footer from './Footer';
import { CardsData } from './CardsData';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { BreadCrumbData } from './CardsData';
import Breadcrumb from './Breadcrumb';

export default function Cards() {

  return (
    <>
        <MainNav/>
        {BreadCrumbData.map((data) => {
            return(
                <Breadcrumb title={data.title} desc={data.desc} />
            );
        })}
        <div className="row m-0 mt-5 d-flex justify-content-between">
            {CardsData.map((data, key) => {
                return (
                    <div className=" p-0 pt-2 bg-body rounded float-left col-sm-12 col-md-4 col-xl-4 ">
                        <p>{data.title}</p>
                            <img src={data.src} alt={data.alt} width="100%" />
                    </div>
                );
            })}
        </div>
        <SocialNetworks />
        <Footer />  
    </>
  );
};