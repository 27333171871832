import React from 'react';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import MainNav from './MainNav';
import SocialNetworks from './SocialNetworks';
import Footer from './Footer';
import { BreadCrumbData } from './videoData';
import { videoData } from './videoData';
import Breadcrumb from './Breadcrumb';

export default function Videos() {
  
  return (
    <>
        <MainNav/>
        {BreadCrumbData.map((data) => {
            return(
                <Breadcrumb title={data.title} desc={data.desc} />
            );
        })}
        <div className="row justify-content-md-center">
            {videoData.map((data, key) => {
                return (
                    <div className="shadow p-0 m-2 pt-2 bg-body rounded float-left col-sm-12 col-md-4 col-xl-3 ">
                        <p>{data.title}</p>
                        <iframe title="YouTube video player" 
                            src={data.iframe} 
                            width="100%" height="315" frameborder="0" allowfullscreen="allowfullscreen">
                        </iframe>
                        <p>                          
                            <Button variant="secondary" className='mt-1'
                            onClick={() => {alert('Novidades em Breve!');}} 
                                >Saiba Mais
                                <FontAwesomeIcon size="lg" icon={ faCaretRight } />
                            </Button>             
                        </p>
                    </div>
                );
            })}
        </div>  
        <SocialNetworks />
        <Footer />  
    </>
  );

};