export const portFolioData = [
    {
        title: "Portfolio",
        desc: "OSProjetos",
    }
];

export const mainVueJSECommerce = [
    {
        "title": "VueJS E-Commerce",
        "p1": "Frontend de Loja Virtual Desenvolvida em VueJS com o framework NUXT, empregando uma pipeline CI/CD com netlify e github, o conceito de reatividade (carregamento assíncrono) e o layout responsivo (compatível com diferentes dispositivos, celulares, computadores, SmartTvs e etc).",
        //require important!
        "img1": require("../../assets/images/vuejs_ecommerce1.jpg"),
        "alt1": "VueJS E-Commerce",
        "subtittle": "Tecnologias Utilizadas",
        "p4": "VueJS, NUXT, Javascript, HTML5, CSS3",
        "appName": "VueJS E-Commerce",
        "link": "https://vuejsecommerce.netlify.app/",
    }
];

export const sidebarVueJSECommerce = [
    {   
        "sidebarTitle": "Full Page View",
        "imgFull": require("../../assets/images/vuejs_ecommerce_fullpage.png"),
        "alt": "VueJS ECommerce Full Page Layout",
        "subtitle": "Outros Projetos",
        "link1": "/portfolio",
        "link1Page": "Portfolio",
        "link2": "/portfolio/vuejs_ecommerce",
        "link2Page": "VueJS ECommerce",
        "link3": "/portfolio/react_spa",
        "link3Page": "React SPA",
        "link4Page": "AppSkore", 
        "link4": "/portfolio/appskore",
        "link5": "/portfolio/netflix_clone",
        "link5Page": "Netflix Clone",
        "link6": "/portfolio/react_google_chart",
        "link6Page": "React Google Charts",
        "link7": "/portfolio/sitedoed",
        "link7Page": "Site do Ed", 
        "link8": "/portfolio/grupotribunacadastro",
        "link8Page": "Cadastro Geral - GRUPO TRIBUNA",
        "link9": "/portfolio/ciadofisico",
        "link9Page": "Cia do Físico",
        "link10": "/portfolio/projeto_recupera",
        "link10Page": "Projeto Recupera",
        "link11": "/portfolio/grupotribunageradordeassinatura",
        "link11Page": "Gerador de Assinatura - GRUPO TRIBUNA", 
        "link12": "/portfolio/mundoong",
        "link12Page": "Mundo ONG",
        "link13": "/portfolio/acamby",
        "link13Page": "Acamby Trade",    
    }
];

export const mainNetflixClone = [
    {
        "title": "Netflix Clone",
        "p1": "Este projeto, como o próprio nome diz, é um clone da NetfLix, com as principais funções da plataforma original, como trailers, sinopses e outros dados obtidos no site, especializado em filmes e séries, TMDB - The Movie Database, https://www.themoviedb.org/. Os dados em questão são públicos e disponibilizados, gratuitamente, via API - Application Programming Interface, para os desenvolvedores inscritos no site. ",
        //require important!
        "img1": require("../../assets/images/netflix_clone1.png"),
        "alt1": "Netflix Clone",
        "p2": "Ele visa explorar diferentes técnicas de desenvolvimento FrontEnd, para construção de layouts bonitos, responsivos e inteligentes, observando as melhores práticas de UX/UI (User Experience e User Interface). Para isso, usamos uma das bibliotecas, gratuitas e de código aberto, mais utilizadas no momento, o ReactJS, ou simplesmente, React, mantida pela equipe do Facebook(Meta).",
        "p3": "A ideia não é original e foi inspirada em diversos vídeos de outros desenvolvedores na internet. Por isso, nosso projeto deve apresentar algumas diferenças em relação a projetos semelhantes. De qualquer forma, nosso objetivo foi alcançado e o projeto ficou bem interessante! Vale a pena conferir, no link abaixo. Agradecemos a todos desenvolvedores que publicaram vídeos, tutoriais e outros materiais, que de alguma forma, nos motivaram a construir nossa própria versão do clone. ",
        "subtittle": "Tecnologias Utilizadas",
        "p4": "React, dados carregados via API da base de dados online: TMDB",
        "appName": "Netflix Clone",
        "link": "https://netflix-clone-sitedoed.netlify.app",
    }
];

export const sidebarNetflixClone = [
    {   
        "sidebarTitle": "Full Page View",
        "imgFull": require("../../assets/images/netflix_clone_fullpage.png"),
        "alt": "Netflix Clone Full Page Layout",
        "subtitle": "Outros Projetos",
        "link1": "/portfolio",
        "link1Page": "Portfolio",
        "link2": "/portfolio/vuejs_ecommerce",
        "link2Page": "VueJS ECommerce",
        "link3": "/portfolio/react_spa",
        "link3Page": "React SPA",
        "link4Page": "AppSkore", 
        "link4": "/portfolio/appskore",
        "link5": "/portfolio/netflix_clone",
        "link5Page": "Netflix Clone",
        "link6": "/portfolio/react_google_chart",
        "link6Page": "React Google Charts",
        "link7": "/portfolio/sitedoed",
        "link7Page": "Site do Ed", 
        "link8": "/portfolio/grupotribunacadastro",
        "link8Page": "Cadastro Geral - GRUPO TRIBUNA",
        "link9": "/portfolio/ciadofisico",
        "link9Page": "Cia do Físico",
        "link10": "/portfolio/projeto_recupera",
        "link10Page": "Projeto Recupera",
        "link11": "/portfolio/grupotribunageradordeassinatura",
        "link11Page": "Gerador de Assinatura - GRUPO TRIBUNA", 
        "link12": "/portfolio/mundoong",
        "link12Page": "Mundo ONG",
        "link13": "/portfolio/acamby",
        "link13Page": "Acamby Trade",     
    }
];

export const mainAppSkore = [
    {
        "title": "AppSkore",
        "p1": "Um projeto reativo, desenvolvido em VueJS com NUXT, recebendo e listando diferentes tipos de dados de uma API GraphQL. Cada link possui um tipo de conteúdo diferente (vídeo, gráfico, documento e etc). Além disso, utilizamos uma pipeline de desenvolvimento dinâmico CI/CD com netlify e GitHub.",
        "img1": require("../../assets/images/appskore0.jpg"),
        "alt1": "AppSkore",
        "subtittle": "Tecnologias Utilizadas",
        "p4": "Html5, GraphQL, VueJS, NUXT, CSS3",
        "appName": "AppSkore",
        "link": "https://appskore.netlify.app/",
    }
];

export const sidebarAppSkore = [
    {
        "sidebarTitle": "Full Page View",
        "imgFull": require("../../assets/images/appskore_fullpage.png"),
        "alt": "Netflix Clone Full Page Layout",
        "subtitle": "Outros Projetos",
        "link1": "/portfolio",
        "link1Page": "Portfolio",
        "link2": "/portfolio/vuejs_ecommerce",
        "link2Page": "VueJS ECommerce",
        "link3": "/portfolio/react_spa",
        "link3Page": "React SPA",
        "link4Page": "AppSkore", 
        "link4": "/portfolio/appskore",
        "link5": "/portfolio/netflix_clone",
        "link5Page": "Netflix Clone",
        "link6": "/portfolio/react_google_chart",
        "link6Page": "React Google Charts",
        "link7": "/portfolio/sitedoed",
        "link7Page": "Site do Ed", 
        "link8": "/portfolio/grupotribunacadastro",
        "link8Page": "Cadastro Geral - GRUPO TRIBUNA",
        "link9": "/portfolio/ciadofisico",
        "link9Page": "Cia do Físico",
        "link10": "/portfolio/projeto_recupera",
        "link10Page": "Projeto Recupera",
        "link11": "/portfolio/grupotribunageradordeassinatura",
        "link11Page": "Gerador de Assinatura - GRUPO TRIBUNA", 
        "link12": "/portfolio/mundoong",
        "link12Page": "Mundo ONG",
        "link13": "/portfolio/acamby",
        "link13Page": "Acamby Trade",     
    }
];

export const mainReactSPA = [
    {
        "title": "React SPA",
        "p1": "O React SPA - Simple Page Application - é um projeto responsivo, de página única, com diversos componentes e efeitos interessantes, desenvolvido para demonstrar algumas das principais funções da biblioteca ReactJS, bem como a possiblidade de integrá-la com outras libs como Bootstrap e FontAwesome. Além disso, ilustra um processo de CI/CD, integrando GitHub com Netlify, que sincroniza, automaticamente, os ambientes local e de produção, homologação ou qualquer outro, conforme a demanda e o sistema de versionamento.",
        "img1": require("../../assets/images/react_spa1.png"),
        "alt1": "Netflix Clone",
        "p2": "Desenvolvido para iniciar a nossa série de tutoriais, o React SPA também resolve um conflito muito comum e que tira o sono de muitos desenvolvedores iniciantes, a falha na integração entre GIT e Netlify, ou plataformas similares. Com isso, pretendemos simular o processo de desenvolvimento real, no qual muitas vezes são necessárias adaptações entre tecnologias diferentes e diversos problemas podem ocorrer.",
        "p3": "Utilizamos o inglês como idioma em nossos tutoriais, por ser o padrão das aplicações no mercado de TI e as melhores fontes de informação estarem nele. Por isso, é imprescindível, pelo menos, seu conhecimento técnico para desenvolvedores de quaisquer linguagens de programação. Esperamos, dessa forma, contribuir para o aperfeiçoamento profissional de nossos amigos Devs, em todos os níveis e habilidades possíveis.  ",
        "subtittle": "Tecnologias Utilizadas",
        "p4": "Html5, CSS, Javascript, ReactJS, Bootstrap, FontAwesome, jQuery",
        "appName": "React SPA Tutorial",
        "link": "https://react-spa-tutorial.netlify.app/",
    }
];

export const sidebarReactSPA = [
    {
        "sidebarTitle": "Full Page View",
        "imgFull": require("../../assets/images/react_spa_fullpage.png"),
        "alt": "Netflix Clone Full Page Layout",
        "subtitle": "Outros Projetos",
        "link1": "/portfolio",
        "link1Page": "Portfolio",
        "link2": "/portfolio/vuejs_ecommerce",
        "link2Page": "VueJS ECommerce",
        "link3": "/portfolio/react_spa",
        "link3Page": "React SPA",
        "link4Page": "AppSkore", 
        "link4": "/portfolio/appskore",
        "link5": "/portfolio/netflix_clone",
        "link5Page": "Netflix Clone",
        "link6": "/portfolio/react_google_chart",
        "link6Page": "React Google Charts",
        "link7": "/portfolio/sitedoed",
        "link7Page": "Site do Ed", 
        "link8": "/portfolio/grupotribunacadastro",
        "link8Page": "Cadastro Geral - GRUPO TRIBUNA",
        "link9": "/portfolio/ciadofisico",
        "link9Page": "Cia do Físico",
        "link10": "/portfolio/projeto_recupera",
        "link10Page": "Projeto Recupera",
        "link11": "/portfolio/grupotribunageradordeassinatura",
        "link11Page": "Gerador de Assinatura - GRUPO TRIBUNA", 
        "link12": "/portfolio/mundoong",
        "link12Page": "Mundo ONG",
        "link13": "/portfolio/acamby",
        "link13Page": "Acamby Trade",   
    }
];

export const mainReactGoogleChart = [
    {
        "title": "React Google Chart",
        "p1": "O React Google Chart é uma aplicação de página única (SPA - Single Page Application) que gera gráficos automaticamente, com os dados inseridos pelo usuário. Desenvolvida em REACT, utilizando a biblioteca de estilos Material do Google. Inicialmente, ela vem com alguns dados fictícios para demonstrar suas funcionalidades e layout. Os dados são substituídos de forma assíncrona e o layout é atualizado, toda vez que novos dados são inseridos no sistema.",
        "img1": require("../../assets/images/react_google_chart1.png"),
        "alt1": "Netflix Clone",
        "p2": "Os dados iniciais estão armazenados em um arquivo javascript. E também são renderizados automaticamente e reativamente no frontend a cada atualização, simulando o processo de consumo de APIs externas ou de um banco de dados interno. Por isso, independente da fonte dos dados, sejam eles dados inseridos pelo usuário ou pré-existentes, o sistema conta com atualizações dinâmicas. O que é uma das grandes vantagens desse tipo de tecnologia.",
        "p3": "Outro conceito importante utilizado nesta aplicação é o de: Mobile First (design desenvolvido primeiramente para celulares). Além do layout responsivo, ou seja, adaptável a diferentes dispositivos, como tablets e computadores. Abaixo segue o link da demonstração online, que demonstra o uso de uma pipeline CI/CD com github e netlify.",
        "subtittle": "Tecnologias Utilizadas",
        "p4": "React, Material, Javascript, HTML5, CSS3",
        "appName": "React Google Chart",
        "link": "https://react-google-charts-sitedoed.netlify.app",
    }
];

export const sidebarReactGoogleChart = [
    {
        "sidebarTitle": "Full Page (App View)",
        "imgFull": require("../../assets/images/react_google_chart_fullpage.png"),
        "alt": "React Google Chart",
        "subtitle": "Outros Projetos",
        "link1": "/portfolio",
        "link1Page": "Portfolio",
        "link2": "/portfolio/vuejs_ecommerce",
        "link2Page": "VueJS ECommerce",
        "link3": "/portfolio/react_spa",
        "link3Page": "React SPA",
        "link4Page": "AppSkore", 
        "link4": "/portfolio/appskore",
        "link5": "/portfolio/netflix_clone",
        "link5Page": "Netflix Clone",
        "link6": "/portfolio/react_google_chart",
        "link6Page": "React Google Charts",
        "link7": "/portfolio/sitedoed",
        "link7Page": "Site do Ed", 
        "link8": "/portfolio/grupotribunacadastro",
        "link8Page": "Cadastro Geral - GRUPO TRIBUNA",
        "link9": "/portfolio/ciadofisico",
        "link9Page": "Cia do Físico",
        "link10": "/portfolio/projeto_recupera",
        "link10Page": "Projeto Recupera",
        "link11": "/portfolio/grupotribunageradordeassinatura",
        "link11Page": "Gerador de Assinatura - GRUPO TRIBUNA", 
        "link12": "/portfolio/mundoong",
        "link12Page": "Mundo ONG",
        "link13": "/portfolio/acamby",
        "link13Page": "Acamby Trade",      
    }
];

export const mainSitedoEd = [
    {
        "title": "Site do Ed",
        "p1": "Este é o nosso site anterior, desenvolvido com uma STACK LAMP (Linux, Apache, MySQL, PHP), LARAVEL e BOOTSTRAP e o padrão MVC (Model, View, Controller). Também utilizamos o plugin SOCIALITE para login por redes sociais como Facebook, Twitter ou nosso banco de dados de usuários e um dos melhores editores de textos de código aberto, o TinyMCE, para a publicação de textos, imagens e vídeos.   ",
        "img1": require("../../assets/images/sitedoed0.png"),
        "alt1": "Netflix Clone",
        "p2": "O layout atual, o que você está acessando, foi inspirado no anterior. Há poucas mudanças nesse sentido, inclusive mantivemos o uso de componentes como o “Carousel” e grande parte dos conceitos de CSS como os breakpoints das media queries, os grids e as posições dos elementos na tela.",
        "subtittle": "Tecnologias Utilizadas",
        "p4": "Stack LAMP (Linux, Apache, MySQL, PHP), LARAVEL e BOOTSTRAP, Padrão: MVC",
        "appName": "Site do Ed",
        "link": "https://www.sitedoed.com.br/",
    }
];

export const sidebarSitedoEd = [
    {
        "sidebarTitle": "Full Page View",
        "imgFull": require("../../assets/images/sitedoed_fullpage.png"),
        "alt": "Site do Ed Full Page Layout",
        "subtitle": "Outros Projetos",
        "link1": "/portfolio",
        "link1Page": "Portfolio",
        "link2": "/portfolio/vuejs_ecommerce",
        "link2Page": "VueJS ECommerce",
        "link3": "/portfolio/react_spa",
        "link3Page": "React SPA",
        "link4Page": "AppSkore", 
        "link4": "/portfolio/appskore",
        "link5": "/portfolio/netflix_clone",
        "link5Page": "Netflix Clone",
        "link6": "/portfolio/react_google_chart",
        "link6Page": "React Google Charts",
        "link7": "/portfolio/sitedoed",
        "link7Page": "Site do Ed", 
        "link8": "/portfolio/grupotribunacadastro",
        "link8Page": "Cadastro Geral - GRUPO TRIBUNA",
        "link9": "/portfolio/ciadofisico",
        "link9Page": "Cia do Físico",
        "link10": "/portfolio/projeto_recupera",
        "link10Page": "Projeto Recupera",
        "link11": "/portfolio/grupotribunageradordeassinatura",
        "link11Page": "Gerador de Assinatura - GRUPO TRIBUNA", 
        "link12": "/portfolio/mundoong",
        "link12Page": "Mundo ONG",
        "link13": "/portfolio/acamby",
        "link13Page": "Acamby Trade",    
    }
];

export const mainGrupoTribunaCadastro = [
    {
        "title": "Cadastro Geral - GRUPO TRIBUNA",
        "p1": "Este projeto é um cadastro geral (usuários, eventos, ramais e etc) do GRUPO TRIBUNA, com uma interface online pública, disponível para todos usuários e uma área restrita, com diferentes níveis de acesso (Administrador, Usuários e Clientes) para funcionários e demais usuários registrados.  Além de seção de perguntas e respostas (FAQ - Frequent Asked Questions) e alguns links úteis de outros projetos da empresa.",
        "img1": require("../../assets/images/grupotribuna_cadastro.png"),
        "alt1": "Cadastro Geral GRUPO TRIBUNA",
        "p2": "Um desafio interessante desse projeto foi desenvolver um sistema que criava novos campos em algumas tabelas do banco de dados (MysQL), de acordo com as peculiaridades específicas de cada evento. Além do uso de técnicas avançadas de normalização dos dados para evitar o truncamento de informações.",
        "subtittle": "Tecnologias Utilizadas",
        "p4": "Stack LAMP (Linux, Apache, MySQL, PHP), LARAVEL e BOOTSTRAP, Padrão: MVC",
        "appName": "Cadastro Geral - GRUPO TRIBUNA",
        "link": "https://grupotribunacadastro.netlify.app/",
    }
];

export const sidebarGrupoTribunaCadastro = [
    {
        "sidebarTitle": "DashBoard View",
        "imgFull": require("../../assets/images/grupotribuna_cadastro_fullpage.jpg"),
        "alt": "Netflix Clone Full Page Layout",
        "subtitle": "Outros Projetos",
        "link1": "/portfolio",
        "link1Page": "Portfolio",
        "link2": "/portfolio/vuejs_ecommerce",
        "link2Page": "VueJS ECommerce",
        "link3": "/portfolio/react_spa",
        "link3Page": "React SPA",
        "link4Page": "AppSkore", 
        "link4": "/portfolio/appskore",
        "link5": "/portfolio/netflix_clone",
        "link5Page": "Netflix Clone",
        "link6": "/portfolio/react_google_chart",
        "link6Page": "React Google Charts",
        "link7": "/portfolio/sitedoed",
        "link7Page": "Site do Ed", 
        "link8": "/portfolio/grupotribunacadastro",
        "link8Page": "Cadastro Geral - GRUPO TRIBUNA",
        "link9": "/portfolio/ciadofisico",
        "link9Page": "Cia do Físico",
        "link10": "/portfolio/projeto_recupera",
        "link10Page": "Projeto Recupera",
        "link11": "/portfolio/grupotribunageradordeassinatura",
        "link11Page": "Gerador de Assinatura - GRUPO TRIBUNA", 
        "link12": "/portfolio/mundoong",
        "link12Page": "Mundo ONG",
        "link13": "/portfolio/acamby",
        "link13Page": "Acamby Trade",     
    }
];

export const mainCiadoFisico = [
    {
        "title": "Cia do Físico",
        "p1": "O site da Cia do Físico foi desenvolvido em HTML5, Javascript, Ajax e CSS, com Bootstrap e algumas bibliotecas Jquery como a  LightBox. Foram utilizadas técnicas de “tagueamento” e otimização orgânica para os mecanismos de busca. Também produzimos fotografias e outros elementos de identidade visual para o projeto.",
        "img1": require("../../assets/images/cia_do_fisico0.png"),
        "alt1": "Netflix Clone",
        "subtittle": "Tecnologias Utilizadas",
        "p4": "HTML5, Javascript, Ajax, CSS, Jquery e Bootstrap",
        "appName": "Cia do Físico",
        "link": "https://ciadofisico.netlify.app",
    }
];

export const sidebarCiadoFisico = [
    {
        "sidebarTitle": "Outras Seções",
        "imgFull": require("../../assets/images/cia_do_fisico_fullpage.png"),
        "alt": "Netflix Clone Full Page Layout",
        "subtitle": "Outros Projetos",
        "link1": "/portfolio",
        "link1Page": "Portfolio",
        "link2": "/portfolio/vuejs_ecommerce",
        "link2Page": "VueJS ECommerce",
        "link3": "/portfolio/react_spa",
        "link3Page": "React SPA",
        "link4Page": "AppSkore", 
        "link4": "/portfolio/appskore",
        "link5": "/portfolio/netflix_clone",
        "link5Page": "Netflix Clone",
        "link6": "/portfolio/react_google_chart",
        "link6Page": "React Google Charts",
        "link7": "/portfolio/sitedoed",
        "link7Page": "Site do Ed", 
        "link8": "/portfolio/grupotribunacadastro",
        "link8Page": "Cadastro Geral - GRUPO TRIBUNA",
        "link9": "/portfolio/ciadofisico",
        "link9Page": "Cia do Físico",
        "link10": "/portfolio/projeto_recupera",
        "link10Page": "Projeto Recupera",
        "link11": "/portfolio/grupotribunageradordeassinatura",
        "link11Page": "Gerador de Assinatura - GRUPO TRIBUNA", 
        "link12": "/portfolio/mundoong",
        "link12Page": "Mundo ONG",
        "link13": "/portfolio/acamby",
        "link13Page": "Acamby Trade",    
    }
];

export const mainProjetoRecupera = [
    {
        "title": "Projeto Recupera",
        "p1": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reiciendis, eius mollitia suscipit, quisquam doloremque distinctio perferendis et doloribus unde architecto optio laboriosam porro adipisci sapiente officiis nemo accusamus ad praesentium? Esse minima nisi et. Dolore perferendis, enim praesentium omnis,iste doloremque quia officia optio deserunt molestiae voluptates soluta architecto tempora.",
        "img1": require("../../assets/images/projeto_recupera0.png"),
        "alt1": "Projeto Recupera",
        "p2": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reiciendis, eius mollitia suscipit, quisquam doloremque distinctio perferendis et doloribus unde architecto optio laboriosam porro adipisci sapiente officiis nemo accusamus ad praesentium? Esse minima nisi et. Dolore perferendis, enim praesentium omnis,iste doloremque quia officia optio deserunt molestiae voluptates soluta architecto tempora.",
        "p3": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reiciendis, eius mollitia suscipit, quisquam doloremque distinctio perferendis et doloribus unde architecto optio laboriosam porro adipisci sapiente officiis nemo accusamus ad praesentium? Esse minima nisi et. Dolore perferendis, enim praesentium omnis,iste doloremque quia officia optio deserunt molestiae voluptates soluta architecto tempora.",
        "subtittle": "Tecnologias Utilizadas",
        "p4": "React, dados carregados via API da base de dados online: TMDB",
        "appName": "Projeto Recupera",
        "link": "https://www.sitedoed.com.br/",
    }
];

export const sidebarProjetoRecupera = [
    {
        "sidebarTitle": "Full Page (App View)",
        "imgFull": require("../../assets/images/projeto_recupera_fullpage.png"),
        "alt": "Projeto Recupera Full Page Layout",
        "subtitle": "Outros Projetos",
        "link1": "/portfolio",
        "link1Page": "Portfolio",
        "link2": "/portfolio/vuejs_ecommerce",
        "link2Page": "VueJS ECommerce",
        "link3": "/portfolio/react_spa",
        "link3Page": "React SPA",
        "link4Page": "AppSkore", 
        "link4": "/portfolio/appskore",
        "link5": "/portfolio/netflix_clone",
        "link5Page": "Netflix Clone",
        "link6": "/portfolio/react_google_chart",
        "link6Page": "React Google Charts",
        "link7": "/portfolio/sitedoed",
        "link7Page": "Site do Ed", 
        "link8": "/portfolio/grupotribunacadastro",
        "link8Page": "Cadastro Geral - GRUPO TRIBUNA",
        "link9": "/portfolio/ciadofisico",
        "link9Page": "Cia do Físico",
        "link10": "/portfolio/projeto_recupera",
        "link10Page": "Projeto Recupera",
        "link11": "/portfolio/grupotribunageradordeassinatura",
        "link11Page": "Gerador de Assinatura - GRUPO TRIBUNA", 
        "link12": "/portfolio/mundoong",
        "link12Page": "Mundo ONG",
        "link13": "/portfolio/acamby",
        "link13Page": "Acamby Trade",   
    }
];

export const mainGrupoTribunaGeradordeAssinatura = [
    {
        "title": "Gerador de Assinatura - GRUPO TRIBUNA",
        "p1": "Essa é uma aplicação online desenvolvida em HTML5, CSS3 e Javascript, para gerar, automaticamente, assinaturas de e-mail personalizadas, para os funcionários do GRUPO TRIBUNA, padronizando suas comunicações eletrônicas.",
        "img1": require("../../assets/images/grupotribuna_gerador_de_assinatura.jpg"),
        "alt1": "Cadastro Geral GRUPO TRIBUNA",
        "subtittle": "Tecnologias Utilizadas",
        "p4": "HTML5, CSS3, Javascript",
        "appName": "Gerador de Assinaturas - GRUPO TRIBUNA",
        "link": "https://atdigital.com.br/assinatura/",
    }
];

export const sidebarGrupoTribunaGeradordeAssinatura = [
    {
        "sidebarTitle": "Full Page View",
        "imgFull": require("../../assets/images/grupotribuna_geradorde_assinatura_fullpage.jpg"),
        "alt": "Grupo Tribuna Gerador de Assinatura Full Page Layout",
        "subtitle": "Outros Projetos",
        "link1": "/portfolio",
        "link1Page": "Portfolio",
        "link2": "/portfolio/vuejs_ecommerce",
        "link2Page": "VueJS ECommerce",
        "link3": "/portfolio/react_spa",
        "link3Page": "React SPA",
        "link4Page": "AppSkore", 
        "link4": "/portfolio/appskore",
        "link5": "/portfolio/netflix_clone",
        "link5Page": "Netflix Clone",
        "link6": "/portfolio/react_google_chart",
        "link6Page": "React Google Charts",
        "link7": "/portfolio/sitedoed",
        "link7Page": "Site do Ed", 
        "link8": "/portfolio/grupotribunacadastro",
        "link8Page": "Cadastro Geral - GRUPO TRIBUNA",
        "link9": "/portfolio/ciadofisico",
        "link9Page": "Cia do Físico",
        "link10": "/portfolio/projeto_recupera",
        "link10Page": "Projeto Recupera",
        "link11": "/portfolio/grupotribunageradordeassinatura",
        "link11Page": "Gerador de Assinatura - GRUPO TRIBUNA", 
        "link12": "/portfolio/mundoong",
        "link12Page": "Mundo ONG",
        "link13": "/portfolio/acamby",
        "link13Page": "Acamby Trade",   
    }
];

export const mainMundoOng = [
    {
        "title": "Mundo ONG",
        "p1": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reiciendis, eius mollitia suscipit, quisquam doloremque distinctio perferendis et doloribus unde architecto optio laboriosam porro adipisci sapiente officiis nemo accusamus ad praesentium? Esse minima nisi et. Dolore perferendis, enim praesentium omnis,iste doloremque quia officia optio deserunt molestiae voluptates soluta architecto tempora.",
        "img1": require("../../assets/images/mundo_ong1.png"),
        "alt1": "Projeto Recupera",
        "p2": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reiciendis, eius mollitia suscipit, quisquam doloremque distinctio perferendis et doloribus unde architecto optio laboriosam porro adipisci sapiente officiis nemo accusamus ad praesentium? Esse minima nisi et. Dolore perferendis, enim praesentium omnis,iste doloremque quia officia optio deserunt molestiae voluptates soluta architecto tempora.",
        "p3": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reiciendis, eius mollitia suscipit, quisquam doloremque distinctio perferendis et doloribus unde architecto optio laboriosam porro adipisci sapiente officiis nemo accusamus ad praesentium? Esse minima nisi et. Dolore perferendis, enim praesentium omnis,iste doloremque quia officia optio deserunt molestiae voluptates soluta architecto tempora.",
        "subtittle": "Tecnologias Utilizadas",
        "p4": "React, dados carregados via API da base de dados online: TMDB",
        "appName": "Mundo ONG",
        "link": "https://www.sitedoed.com.br/",
    }
];

export const sidebarMundoOng = [
    {
        "sidebarTitle": "Full Page",
        "imgFull": require("../../assets/images/mundo_ong_fullpage.png"),
        "alt": "Projeto Recupera Full Page Layout",
        "subtitle": "Outros Projetos",
        "link1": "/portfolio",
        "link1Page": "Portfolio",
        "link2": "/portfolio/vuejs_ecommerce",
        "link2Page": "VueJS ECommerce",
        "link3": "/portfolio/react_spa",
        "link3Page": "React SPA",
        "link4Page": "AppSkore", 
        "link4": "/portfolio/appskore",
        "link5": "/portfolio/netflix_clone",
        "link5Page": "Netflix Clone",
        "link6": "/portfolio/react_google_chart",
        "link6Page": "React Google Charts",
        "link7": "/portfolio/sitedoed",
        "link7Page": "Site do Ed", 
        "link8": "/portfolio/grupotribunacadastro",
        "link8Page": "Cadastro Geral - GRUPO TRIBUNA",
        "link9": "/portfolio/ciadofisico",
        "link9Page": "Cia do Físico",
        "link10": "/portfolio/projeto_recupera",
        "link10Page": "Projeto Recupera",
        "link11": "/portfolio/grupotribunageradordeassinatura",
        "link11Page": "Gerador de Assinatura - GRUPO TRIBUNA", 
        "link12": "/portfolio/mundoong",
        "link12Page": "Mundo ONG",
        "link13": "/portfolio/acamby",
        "link13Page": "Acamby Trade",     
    }
];


export const mainAcamby = [
    {
        "title": "Acamby Trading",
        "p1": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reiciendis, eius mollitia suscipit, quisquam doloremque distinctio perferendis et doloribus unde architecto optio laboriosam porro adipisci sapiente officiis nemo accusamus ad praesentium? Esse minima nisi et. Dolore perferendis, enim praesentium omnis,iste doloremque quia officia optio deserunt molestiae voluptates soluta architecto tempora.",
        "img1": require("../../assets/images/acamby0.png"),
        "alt1": "Projeto Recupera",
        "p2": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reiciendis, eius mollitia suscipit, quisquam doloremque distinctio perferendis et doloribus unde architecto optio laboriosam porro adipisci sapiente officiis nemo accusamus ad praesentium? Esse minima nisi et. Dolore perferendis, enim praesentium omnis,iste doloremque quia officia optio deserunt molestiae voluptates soluta architecto tempora.",
        "p3": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reiciendis, eius mollitia suscipit, quisquam doloremque distinctio perferendis et doloribus unde architecto optio laboriosam porro adipisci sapiente officiis nemo accusamus ad praesentium? Esse minima nisi et. Dolore perferendis, enim praesentium omnis,iste doloremque quia officia optio deserunt molestiae voluptates soluta architecto tempora.",
        "subtittle": "Tecnologias Utilizadas",
        "p4": "React, dados carregados via API da base de dados online: TMDB",
        "appName": "Acamby",
        "link": "http://acamby.com.br/",
    }
];

export const sidebarAcamby = [
    {
        "sidebarTitle": "Full Page",
        "imgFull": require("../../assets/images/acamby_fullpage.png"),
        "alt": "Acamby Full Page Layout",
        "subtitle": "Outros Projetos",
       "link1": "/portfolio",
        "link1Page": "Portfolio",
        "link2": "/portfolio/vuejs_ecommerce",
        "link2Page": "VueJS ECommerce",
        "link3": "/portfolio/react_spa",
        "link3Page": "React SPA",
        "link4Page": "AppSkore", 
        "link4": "/portfolio/appskore",
        "link5": "/portfolio/netflix_clone",
        "link5Page": "Netflix Clone",
        "link6": "/portfolio/react_google_chart",
        "link6Page": "React Google Charts",
        "link7": "/portfolio/sitedoed",
        "link7Page": "Site do Ed", 
        "link8": "/portfolio/grupotribunacadastro",
        "link8Page": "Cadastro Geral - GRUPO TRIBUNA",
        "link9": "/portfolio/ciadofisico",
        "link9Page": "Cia do Físico",
        "link10": "/portfolio/projeto_recupera",
        "link10Page": "Projeto Recupera",
        "link11": "/portfolio/grupotribunageradordeassinatura",
        "link11Page": "Gerador de Assinatura - GRUPO TRIBUNA", 
        "link12": "/portfolio/mundoong",
        "link12Page": "Mundo ONG",
        "link13": "/portfolio/acamby",
        "link13Page": "Acamby Trade",  
    }
];